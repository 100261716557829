.LinkGallery__content .Button {
  font-family: var(--text-font), 'sans-serif' !important;
}

.LinkGallery__content .LinkGallery__content--buttons-transparent.Button {
  border-color: var(--text-color);
  color: var(--text-color);
}

.LinkGallery__content .LinkGallery__content--buttons-transparent.Button--secondary:not(.Button--action):not(:disabled):hover {
  background: var(--text-color);
  border-color: var(--text-color);
  color: var(--background-gradient-start);
}

.LinkGallery__content .LinkGallery__content--buttons-transparent.Button--secondary:not(.Button--action):not(:disabled):focus,
.LinkGallery__content .LinkGallery__content--buttons-transparent.Button--secondary:not(.Button--action):not(:disabled):active {
  background: none;
  border-color: var(--text-color);
  color: var(--text-color);
}

.LinkGallery__content .LinkGallery__content--buttons-filled.Button {
  border-color: var(--text-color);
  background: var(--text-color);
  color: var(--background-gradient-start);
}

.LinkGallery__content .LinkGallery__content--buttons-filled.Button--secondary:not(.Button--action):not(:disabled):hover {
  background: none;
  border-color: var(--text-color);
  color: var(--text-color);
}

.LinkGallery__content .LinkGallery__content--buttons-filled.Button--secondary:not(.Button--action):not(:disabled):focus,
.LinkGallery__content .LinkGallery__content--buttons-filled.Button--secondary:not(.Button--action):not(:disabled):active {
  border-color: var(--text-color);
  background: var(--text-color);
  color: var(--background-gradient-start);
}

.LinkGallery__content--buttons-rounded {
  border-radius: 50em !important;
}

.Username {
  cursor: default;
  color: var(--text-color);
}

.LinkGallery__content--social-icons a {
  margin: 0 10px;
}

.LinkGallery__content--social-icons svg {
  fill: var(--text-color);
}

.LinkGallery__content--social-icons > .A:active:not(:disabled) svg,
.LinkGallery__content--social-icons > .A:focus:not(:disabled) svg {
  fill: var(--text-color) !important;
}

.LinkGallery__content--social-icons > .A:hover:not(:disabled) svg {
  fill: var(--text-color) !important;
  opacity: .7;
}

/* TO REMOVE */
.LinkGallery__content--social-icons i:not(.website):not(.email) > svg {
  height: 20px!important;
  width: 20px!important;
}

.VideoWrapper {
  border: 1px solid var(--text-color);
  border-radius: 4px;
  overflow: hidden;
  z-index: 1;
  /* Responsive wrapper for iframe (sources: https://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php ; http://alistapart.com/article/creating-intrinsic-ratios-for-video) */
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.VideoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer > div:first-child {
  position: relative;
}

.footer p {
  color: var(--text-color);
}

.footer svg {
  fill: var(--text-color);
  height: 21px;
  width: 194px;
}

.footer .A:active:not(:disabled) svg,
.footer .A:focus:not(:disabled) svg,
.footer .A:hover:not(:disabled) svg {
  fill: var(--text-color)!important;
}

.LoaderPlaceholder {
  border-radius: 8px;
  background-color: var(--color-grey-50);
}

/* TIKTOK */
.TikTokLoader {
  height: 721px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .TikTokModal .ModalContent {
    min-height: 25vh;
    max-height: 75vh;
    height: auto;
  }
}
.TikTokModal .ModalContent {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.TikTokModal .TikTokVideo__title {
  padding: 36px 36px 12px;
}
.TikTokModal .TikTokVideo__wrapper {
  height: calc(100% - 70px);
}
.TikTokModal .TikTokVideo__content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 24px 36px;
}
.TikTokModal .TikTokVideo__buttons {
  border-top: 1px solid var(--color-grey-50);
  padding: 36px 0;
}

@media (max-width: 767px) {
  .TikTokModal.Modal--sm {
    height: calc(100% - 32px);
  }
}
