@import 'https://use.typekit.net/ccp4aew.css';
@import '~@rebrandly/styleguide/dist/index.css';

body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  line-height: 1.3;
}

:root {
  --text-color: #000000;
  --text-font: 'Inter';
  --background-gradient-start: #aaa;
  --background-gradient-end: #fff;
  --background: linear-gradient(var(--background-gradient-start), var(--background-gradient-end));
}

.center {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.center-row {
  display: flex;
  justify-content: center;
}
