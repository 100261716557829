/*
This CSS file not use css custom var because is used also
for component that aren't support by dashboard
 */

.AlertPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 48px auto;
  text-align: center;
}

.AlertPanel--sm {
  max-width: 400px;
}

.AlertPanel__title {
  color: #212732;
  /* color: var(--color-grey-800); */
  font-weight: 400;
  /* font-weight: var(--weight-normal); */
  margin-top: 24px;
}

.AlertPanel--sm .AlertPanel__title,
.AlertPanel--md .AlertPanel__title {
  font-size: 1.5rem;
  /* font-size: var(--display-medium); */
}

.AlertPanel--lg .AlertPanel__title {
  font-size: 2.25rem;
  /* font-size: var(--display-large); */
  font-weight: 300;
  /* font-weight: var(--weight-light); */
}

.AlertPanel__message {
  color: #595d66;
  /* color: var(--color-grey-600); */
  margin-top: 8px;
  line-height: 1.4;
}

.AlertPanel--sm .AlertPanel__message,
.AlertPanel--md .AlertPanel__message {
  font-size: 0.9375rem;
  /* font-size: var(--display-xsmall); */
}

@media (max-width: 767px) {
  .AlertPanel__cta {
    width: 100%;
  }

  .AlertPanel__cta * {
    width: 100%;
  }

  .AlertPanel__icon svg {
    width: 180px;
    height: 120px;
  }
}
